import { SERVER_URL, FETCH_HEADERS } from "../constants/constants";

const options = (method, type, body) => {
  return {
    method: method,
    headers: type,
    body: JSON.stringify(body)
  }
}

export const getNameForSearch = (name) => {
  return name.toLowerCase().replace(/[^a-z-A-Zа-яА-Я0-9]/g, "");
};

export const checkObjectKeys = (obj) => {
  for (let key in obj) {
    const item = obj[key];
    if (item === null || item.length === 0) return false;
  }
  return true
};

export const iterationParents = (element, data) => {
  while (!element.dataset[data]) { element = element.parentNode }
  return element.dataset[data];
};

export const getParentElement = (element, data, value) => {
  while (element.dataset[data] !== value) { element = element.parentNode }
  return element
}

export const resetObject = (obj) => {
  for (let key in obj) {
    obj[key] = null;
  }
  return obj;
};

export const reverseNames = (name) => {
  switch (name) {
    case "Магазины": return 'shops';
    case "Развлечения": return 'entertainments';
    case "Сервисы": return 'services';
    case "Рестораны": return 'foods';
    case "Разное": return 'common';
    default: return;
  }
};

export const compareObjects = async (baseObj, newObj) => {
  baseObj.items = baseObj.items.map((item) => {
    item.isEdit = (item.name === newObj.items.name && item.code === newObj.items.code) && true;
    return item;
  });
  return baseObj;
};

export const autocompleteForSearch = (objects, text) => {
  const words = text.toLowerCase();
  const arrayNames = []
  objects.forEach((item) => {
    const searchingName = item.name.toLowerCase();
    if (searchingName.includes(words)) arrayNames.push(item.name);
  })
  return arrayNames;
};

export const getObjectsFromServer = async (type, name) => {
  const url = name ? `${SERVER_URL}${type}?name=${name}` : `${SERVER_URL}${type}`;
  return await fetch(url, options('GET'))
    .then(res => res.json())
};

export const getOneObjectFromServer = async (type, name, value) => {
  const url = `${SERVER_URL}${type}?name=${name}&value=${value}`;
  return await fetch(url, options('GET'))
    .then(res => res.json())
};

export const addObjectToServer = async (type, name, object) => {
  const url = `${SERVER_URL}${type}?name=${name}`;
  return await fetch(url, options('POST', FETCH_HEADERS, object))
    .then(res => res.json());
};

export const deleteObjectOnServer = async (type, name, object) => {
  const url = `${SERVER_URL}${type}?name=${name}`;
  return await fetch(url, options('DELETE', FETCH_HEADERS, object))
    .then(res => console.log('Удаление произошло успешно'));
};

export const stateEditObject = async (type, name, object, stateEdit, method) => {
  const url = method
    ? `${SERVER_URL}${type}?name=${name}&edit=${stateEdit}&save=${method}`
    : `${SERVER_URL}${type}?name=${name}&edit=${stateEdit}`;
  return await fetch(url, options('PUT', FETCH_HEADERS, object))
    .then(res => console.log('Успех'));
};