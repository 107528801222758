import { combineReducers } from "redux";
import { objectsReducer } from "./objectsReducer";
import { authenticatorReducer } from "./authenticatorReducer";
import { paginatorReducer } from "./paginatorReducer";

export const rootReducer = combineReducers({
  objects: objectsReducer,
  authenticator: authenticatorReducer,
  paginator: paginatorReducer
})
