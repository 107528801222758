import React from 'react';
import './style.scss';
import Table from './Table';

function Main() {
  return (
    <main>
      <h1 className="main__title">Field for administration objects</h1>
      <Table />
    </main>
  );
}

export default Main;
