import { OBJECT_LOADED_SUCCESSFUL, OBJECT_TITLE_LOADED_SUCCESSFUL } from "../constants/actionTypes";


const initialState = {
  objects: {
    titles: []
  },
  disabled: true,
  sortBy: null
}

export const objectsReducer = (state = initialState, action) => {
  if (action)
    switch (action.type) {
      case OBJECT_LOADED_SUCCESSFUL:
        return {
          ...state,
          objects: {
            titles: state.objects.titles,
            items: action.payload.items
          }
        }
      case OBJECT_TITLE_LOADED_SUCCESSFUL:
        return {
          ...state,
          objects: {
            titles: action.payload.titles
          }
        }
      default:
        return state
    }
}