import React from 'react';
import ListsHeaders from './ListsHeaders';
import PopupAddItems from './ListsHeaders/PopupAddItems';

class Table extends React.Component {
  state = {
    isPopup: true,
    objectName: null
  }

  changeStatePopup = (value) => {
    this.setState({
      isPopup: !this.state.isPopup
    });
    if (typeof value === 'string') this.setState({ objectName: value })
  }

  render() {
    return (
      <div>
        <ListsHeaders handlePopup={this.changeStatePopup} />
        <PopupAddItems popup={this.state.isPopup} popupObject={this.state.objectName} handlePopup={this.changeStatePopup} />
      </div >
    );
  }
}

export default Table;
