import React from 'react';
import { connect } from "react-redux";
import { checkObjectKeys, getNameForSearch, resetObject } from '../../../../../commons/helpers';
import { addObject } from '../../../../../actionCreators/objectActions';
import { KEY_ESC } from '../../../../../constants/constants';

class PopupAddItems extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handlerKeydownClosePopup)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handlerKeydownClosePopup)
  }

  state = {
    isValid: true
  }

  object = {
    name: null,
    code: null,
    hardcode: null
  }

  formRef = React.createRef();

  changeValidInputs = (state) => {
    this.setState({ isValid: state })
  }

  resetDataObj = () => {
    this.object = resetObject(this.object);
    this.setState({ isValid: true });
    this.formRef.current.reset();
  }

  handleChange = (evt) => {
    this.changeValidInputs(true);
    const idObj = evt.target.id;
    const valueInput = evt.target.value;
    if (idObj === 'name') this.object.code = getNameForSearch(valueInput);
    this.object[idObj] = valueInput;
  }

  recordValidObject = (name, obj) => {
    this.props.addObject(name, obj);
    this.props.handlePopup();
    this.resetDataObj();
  }

  addObject = (evt) => {
    evt.preventDefault();
    const nameObj = this.props.popupObject;
    checkObjectKeys(this.object) ? this.recordValidObject(nameObj, this.object) : this.changeValidInputs(false);
  }

  closePopup = () => {
    this.resetDataObj();
    this.props.handlePopup();
  }

  handlerKeydownClosePopup = (evt) => {
    if (!this.props.popup && evt.keyCode === KEY_ESC) this.closePopup()
  }

  render() {
    const name = this.props.popupObject
    return (
      <div className={`add-form__wrapper ${this.state.isValid ? '' : 'feedback-error'}`} hidden={this.props.popup}>
        <div className={"add-form__title"}>{name}</div>
        <form className='add-form' ref={this.formRef}>
          <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label add-form__input-wrapper">
            <label className="mdl-textfield__label" htmlFor="name">Имя</label>
            <input onChange={this.handleChange} className="mdl-textfield__input  add-form__input" id="name" type="text" autoFocus={!this.props.popup}></input>
          </div>
          <div className="mdl-textfield mdl-js-textfield add-form__input-wrapper add-form__input-wrapper--textarea">
            <label className="mdl-textfield__label add-form__label" htmlFor="hardcode">Hardcode</label>
            <textarea onChange={this.handleChange} className="mdl-textfield__input add-form__textarea" id="hardcode" type="text"></textarea>
          </div>

          <div className="add-form__button-wrapper">
            <button className="mdl-button mdl-js-button mdl-button--raised mdl-button--colored add-form__button" type="submit" onClick={this.addObject}>Сохранить</button>
            <button className="mdl-button mdl-js-button mdl-button--raised mdl-button--accent add-form__button" type="reset" onClick={this.resetDataObj}>Сбросить</button>
          </div>
        </form>
        <button onClick={this.closePopup} className="add-form__close mdl-button mdl-js-button mdl-button--fab mdl-button--accent"><i className="material-icons">X</i></button>
      </div>
    )
  }
};

const mapDispatchToProps = dispatch => {
  return {
    addObject: (nameObj, obj) => dispatch(addObject(nameObj, obj))
  }
};

export default connect(null, mapDispatchToProps)(PopupAddItems);
