import React from 'react';
import { connect } from "react-redux";
import { getTitles, getObjects } from '../../../../actionCreators/objectActions';
import { reverseNames } from '../../../../commons/helpers';
import ListItems from './ListsItems';
import AddItemButton from './AddItemButton';
import Search from './Search';

class ListsHeaders extends React.Component {
  componentDidMount() {
    this.props.getTitles();
  };

  showElements = (evt) => {
    if (evt.target.closest('.list__obj-item')) return;
    evt.currentTarget.classList.add('active');
    const nameObj = evt.currentTarget.dataset.name;
    this.props.getObjects(nameObj);
  };

  showPopup = (evt) => {
    const nameOfAddingObject = evt.target.dataset.id
    this.props.handlePopup(nameOfAddingObject)
  }

  checkItemCompliance = (obj, name) => {
    return typeof obj === 'object' && obj.name === name
  };

  render() {
    let listItems;

    if (this.props.objects) {
      listItems = this.props.objects.items.map(item => {
        return (
          <ListItems key={item.code} hardcode={item.hardcode} name={item.name} code={item.code} isEdit={item.isEdit} />
        )
      })
    };

    const headers = this.props.titles.map((item, index) => {
      const switchName = reverseNames(item);
      return (
        <li
          key={index.toString()}
          data-name={switchName}
          className={`list-item ${this.checkItemCompliance(this.props.objects, switchName) ? 'list-item--active' : ''}`}
          onClick={this.showElements}
        >
          <h4 className="title">{item}</h4>
          {this.checkItemCompliance(this.props.objects, switchName) &&
            <div className="test-list">
              <ul className="list__obj-item">
                {listItems}
              </ul>
              <AddItemButton id={switchName} addElement={this.showPopup} />
            </div>
          }
        </li>
      )
    });

    return (
      <>
        <ul className="list">{headers}</ul>
        <Search isHidden={this.props.objects ? true : false} objects={this.props.objects} />
      </>
    )
  }
};

const mapStateToProps = state => {
  return {
    titles: state.objects.objects.titles,
    objects: state.objects.objects.items
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getTitles: () => dispatch(getTitles()),
    getObjects: (nameObj) => dispatch(getObjects(nameObj)),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(ListsHeaders);
