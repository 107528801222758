import React from 'react';

function SaveIconButton() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" width="30" height="30">
      <defs>
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="59.95" y1="94.25" x2="59.95" y2="11.7629" gradientTransform="matrix(1 0 0 -1 0 104)">
          <stop offset="0" stopColor='#00EFD1' />
          <stop offset="1" stopColor='#00ACEA' />
        </linearGradient>
      </defs>
      <g>
      <polygon points="496.327,94.041 496.327,501.551 224.653,501.551 224.653,10.449 412.735,10.449 	"/>
      <polygon points="287.347,94.041 287.347,501.551 15.673,501.551 15.673,10.449 203.755,10.449 	"/>
      <polygon points="464.98,94.041 464.98,501.551 47.02,501.551 47.02,10.449 381.388,10.449 	"/>
      <rect x="109.714" y="10.449" width="271.673" height="167.184"/>
      <rect x="109.714" y="10.449" width="240.327" height="167.184"/>
      <rect x="88.816" y="282.122" width="323.918" height="219.429"/>
      <rect x="297.796" y="52.245" width="41.796" height="83.592"/>
      <rect x="88.816" y="282.122" width="292.571" height="219.429"/>
      <g fill="url(#SVGID_2_)">
        <path  d="M496.327,512H15.673c-2.772,0.002-5.43-1.099-7.39-3.059c-1.96-1.96-3.06-4.619-3.059-7.39V10.449
        c-0.002-2.772,1.099-5.43,3.059-7.39s4.619-3.06,7.39-3.059h397.061c2.771,0,5.429,1.101,7.387,3.062l83.592,83.592
        c1.96,1.959,3.062,4.616,3.062,7.387v407.51c0.002,2.772-1.099,5.43-3.059,7.39C501.757,510.901,499.098,512.002,496.327,512z
        M26.122,491.102h459.755V98.367l-77.469-77.469H26.122V491.102z"/>
        <path  d="M381.388,188.082H109.714c-2.772,0.002-5.43-1.099-7.39-3.059c-1.96-1.96-3.06-4.619-3.059-7.39
        V10.449c-0.002-2.772,1.099-5.43,3.059-7.39c1.96-1.96,4.619-3.06,7.39-3.059h271.673c2.772-0.002,5.43,1.099,7.39,3.059
        c1.96,1.96,3.06,4.619,3.059,7.39v167.184c0.002,2.772-1.099,5.43-3.059,7.39C386.818,186.983,384.159,188.083,381.388,188.082
        L381.388,188.082z M120.163,167.184h250.776V20.898H120.163V167.184z"/>
        <path  d="M412.735,512H88.816c-2.772,0.002-5.43-1.099-7.39-3.059c-1.96-1.96-3.06-4.619-3.059-7.39V282.122
        c-0.002-2.772,1.099-5.43,3.059-7.39s4.619-3.06,7.39-3.059h323.918c2.772-0.002,5.43,1.099,7.39,3.059
        c1.96,1.96,3.06,4.619,3.059,7.39v219.429c0.002,2.772-1.099,5.43-3.059,7.39C418.165,510.901,415.506,512.002,412.735,512
        L412.735,512z M99.265,491.102h303.02V292.571H99.265V491.102z"/>
        <path  d="M214.204,344.816h-73.143c-5.771,0-10.449-4.678-10.449-10.449s4.678-10.449,10.449-10.449h73.143
        c5.771,0,10.449,4.678,10.449,10.449S219.975,344.816,214.204,344.816z"/>
        <path  d="M245.551,386.612h-104.49c-5.771,0-10.449-4.678-10.449-10.449c0-5.771,4.678-10.449,10.449-10.449
        h104.49c5.771,0,10.449,4.678,10.449,10.449C256,381.934,251.322,386.612,245.551,386.612z"/>
        <path  d="M276.898,386.612c-2.78,0.028-5.453-1.065-7.419-3.03c-0.952-1.016-1.731-2.181-2.306-3.449
        c-0.5-1.263-0.746-2.612-0.724-3.97c0-3.733,1.992-7.183,5.224-9.049c3.233-1.867,7.216-1.867,10.449,0
        c3.233,1.867,5.224,5.316,5.224,9.049c0.027,2.779-1.065,5.453-3.031,7.418C282.351,385.547,279.677,386.639,276.898,386.612
        L276.898,386.612z"/>
        <path  d="M339.592,146.286h-41.796c-2.772,0.002-5.43-1.099-7.39-3.059c-1.96-1.96-3.06-4.619-3.059-7.39
        V52.245c-0.002-2.772,1.099-5.43,3.059-7.39c1.96-1.96,4.619-3.06,7.39-3.059h41.796c2.772-0.002,5.43,1.099,7.39,3.059
        c1.96,1.96,3.06,4.619,3.059,7.39v83.592c0.002,2.772-1.099,5.43-3.059,7.39C345.022,145.187,342.364,146.287,339.592,146.286
        L339.592,146.286z M308.245,125.388h20.898V62.694h-20.898V125.388z"/>
      </g>
    </g>
      </svg>
  )
}

export default SaveIconButton;