import React from 'react';
import RemoveBasket from './RemoveBasket';
import EditPencil from './EditPencil';
import { connect } from 'react-redux';
import { delObject, editObject, saveChangeObject } from '../../../../../actionCreators/objectActions';
import { iterationParents } from '../../../../../commons/helpers';
import SaveIconButton from './SaveIconButton';
import { KEY_ESC } from '../../../../../constants/constants';

class ListItems extends React.Component {
  componentDidUpdate() {
    document.addEventListener('click', this.resetChangeEditObject);
    document.addEventListener('keydown', this.resetChangeEditObject);
    const checkFocusOnInput = setTimeout(() => {
      this.props.isEdit && this.refFieldContentEditable.current.focus();
      clearTimeout(checkFocusOnInput);
    }, 300)
  }

  componentWillUpdate() {
    document.removeEventListener('click', this.resetChangeEditObject);
    document.removeEventListener('keydown', this.resetChangeEditObject);
  }

  resetChangeEditObject = (evt) => {
    if (!evt.target.closest('.list__item') || evt.keyCode === KEY_ESC) {
      this.props.isEdit && this.props.editObject(this.nameObj, this.object, false);
    }
  }

  refFieldContentEditable = React.createRef();

  nameObj = undefined;

  object = {
    name: this.props.name,
    code: this.props.code,
    hardcode: this.props.hardcode
  }

  handleIconButtons = (evt) => {
    this.nameObj = iterationParents(evt.target, 'name');
    const delButton = evt.target.closest(".icons__delete");
    const editButton = evt.target.closest(".icons__edit");
    if (delButton !== null) this.props.delObject(this.nameObj, this.object);
    else if (editButton !== null) {
      this.props.isEdit
        ? this.props.saveChangeObject(this.nameObj, this.object, false)
        : this.props.editObject(this.nameObj, this.object, true);
    }
  }

  handlerChangeInput = (evt) => {
    this.object.hardcode = evt.currentTarget.textContent
  }

  render() {
    return (
      <li className="list__item" data-id={this.props.code}>
        <div className="icons__wrapper" onClick={this.handleIconButtons}>
          <div className="icons__edit icon">
            {!this.props.isEdit ? <EditPencil /> : <SaveIconButton />}
          </div>
          <div className="icons__delete icon">
            <RemoveBasket />
          </div>
        </div>
        <h3 className="list__item-title">{this.props.name}</h3>
        <p
          className="list__item-text"
          ref={this.refFieldContentEditable}
          onInput={this.handlerChangeInput}
          contentEditable={this.props.isEdit}
        >
          {this.props.hardcode}
        </p>
        <hr />
      </li>
    )
  }
};

const mapDispatchToProps = dispatch => {
  return {
    delObject: (nameObj, obj) => dispatch(delObject(nameObj, obj)),
    editObject: (nameObj, object, stateEdit) => dispatch(editObject(nameObj, object, stateEdit)),
    saveChangeObject: (nameObj, object, stateEdit) => dispatch(saveChangeObject(nameObj, object, stateEdit))
  }
};


export default connect(null, mapDispatchToProps)(ListItems);
