import { OBJECT_TITLE_LOADED_SUCCESSFUL, OBJECT_LOADED_SUCCESSFUL } from "../constants/actionTypes";
import { getObjectsFromServer, addObjectToServer, deleteObjectOnServer, stateEditObject, getOneObjectFromServer } from "../commons/helpers";


async function objectLoadedStore(dispatch, nameObj) {
  await dispatch({
    type: OBJECT_LOADED_SUCCESSFUL,
    payload: {
      items: {
        name: nameObj,
        items: await getObjectsFromServer('objects', nameObj)
      }
    }
  })
}


export const getTitles = () => async dispatch => {
  await dispatch({
    type: OBJECT_TITLE_LOADED_SUCCESSFUL,
    payload: {
      titles: await getObjectsFromServer('title')
    }
  })
};

export const getObjects = (nameObj) => async dispatch => {
  objectLoadedStore(dispatch, nameObj);
};

export const getOneObject = (nameObj, value) => async dispatch => {
  await dispatch({
    type: OBJECT_LOADED_SUCCESSFUL,
    payload: {
      items: {
        name: nameObj,
        items: await getOneObjectFromServer('objects', nameObj, value)
      }
    }
  })
};

export const addObject = (nameObj, object) => async dispatch => {
  await addObjectToServer('objects', nameObj, object);
  objectLoadedStore(dispatch, nameObj)
};

export const delObject = (nameObj, object) => async dispatch => {
  await deleteObjectOnServer('objects', nameObj, object);
  objectLoadedStore(dispatch, nameObj)
};

export const editObject = (nameObj, object, stateEdit) => async dispatch => {
  await stateEditObject('objects', nameObj, object, stateEdit)
  objectLoadedStore(dispatch, nameObj)
};

export const saveChangeObject = (nameObj, object, stateEdit) => async dispatch => {
  await stateEditObject('objects', nameObj, object, stateEdit, true);
  objectLoadedStore(dispatch, nameObj)
};
