import React from 'react';

function RemoveBasket() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 2 98 98" width="30" height="30">
      <defs>
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="59.95" y1="94.25" x2="59.95" y2="11.7629" gradientTransform="matrix(1 0 0 -1 0 104)">
          <stop offset="0" stopColor='#00EFD1' />
          <stop offset="1" stopColor='#00ACEA' />
        </linearGradient>
      </defs>
      <g fill="url(#SVGID_2_)">
        <path  d="M63.8,26.2v-6.5c0-2.8-2.2-5-5-5H39.2c-2.8,0-5,2.2-5,5v6.5H13.3v6h6.3l5,56.4c0.1,1.5,1.4,2.7,3,2.7h42.9   c1.6,0,2.9-1.2,3-2.7l5-56.4h6.4v-6C84.9,26.2,63.8,26.2,63.8,26.2z M40.2,20.7h17.6v5.5H40.2V20.7z M67.7,85.3H30.2l-4.7-53.1   h46.7L67.7,85.3z" />

        <polygon points="63.6,41.1 57.6,40.9 56.3,76.4 62.3,76.6  " />

        <polygon points="41.7,76.4 40.4,40.9 34.4,41.1 35.7,76.6  " />

        <rect x="46" y="41" width="6" height="35.5" />
      </g>
    </svg>
  )
}

export default RemoveBasket;
