export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const OBJECT_LOADED_SUCCESSFUL = "OBJECT_LOADED_SUCCESSFUL";
export const ONE_OBJECT_LOADED_SUCCESSFUL = "ONE_OBJECT_LOADED_SUCCESSFUL";
export const OBJECT_TITLE_LOADED_SUCCESSFUL = "OBJECT_TITLE_LOADED_SUCCESSFUL";
export const OBJECT_ADD = "OBJECT_ADDED";
export const OBJECT_DELETE = "OBJECT_DELETE";
export const OBJECT_CHANGE = "OBJECT_CHANGE";
export const OBJECT_EDIT = "OBJECT_EDIT";
export const OBJECT_SORT = "OBJECT_SORT";
export const OBJECT_SEARCH = "OBJECT_SEARCH";

export const CHANGE_CURRENT_PAGE = "CHANGE_CURRENT_PAGE";

