import { LOGIN, LOGOUT } from "../constants/actionTypes";


const initialState = {
  isLogin: false,
  objects: []
}

export const authenticatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        isLogin: action.payload
      }
    };
    case LOGOUT: {
      return {
        ...state,
        isLogin: action.payload
      }
    };
    default:
      return state
  }
}