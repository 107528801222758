import React from 'react';
import Main from './components/Main';

function App() {
  return (
    <div className="App mdl-grid">
      <header className="App-header mdl-cell mdl-cell--12-col">
        <Main />
      </header>
    </div>
  );
}

export default App;
