import React from 'react';
import { connect } from "react-redux";
import { autocompleteForSearch, getParentElement } from '../../../../../commons/helpers';
import { getOneObject } from '../../../../../actionCreators/objectActions';

class Search extends React.Component {
  componentDidMount() {
    document.addEventListener('click', this.closeSearchList);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeSearchList);
  }

  componentDidUpdate() {
    const array = this.props.objects && this.props.objects.items;
    const name = this.props.objects && this.props.objects.name;
    if (array && array !== this.state.arrayObjects) {
      this.setState({
        arrayObjects: array,
        nameObject: name
      })
    }
  }

  state = {
    arrayObjects: [],
    arrayNames: [],
    isOpenSearchList: false,
    nameObject: ''
  }

  searchInputRef = React.createRef();

  changeStateSearchList = (prevState, nexState) => {
    if (this.state.isOpenSearchList === prevState) this.setState({ isOpenSearchList: nexState })
  }

  handleSearchInput = (evt) => {
    this.changeStateSearchList(true, false)
    const text = evt.target.value;
    const expectedArray = autocompleteForSearch(this.state.arrayObjects, text)
    expectedArray && this.setState({ arrayNames: expectedArray })
  }

  handleChoiceItem = (evt) => {
    const choicedItem = evt.target.textContent;
    const nameObj = this.state.nameObject;
    this.props.getOneObject(nameObj, choicedItem);
    this.changeStateSearchList(false, true);
  }

  closeSearchList = (evt) => {
    const fieldInput = evt.target;
    !fieldInput.closest('.search__list') && this.changeStateSearchList(false, true);
    if (this.state.isOpenSearchList && fieldInput.closest('.list-item ')) {
      this.searchInputRef.current.value = "";
      const wrapperInput = getParentElement(this.searchInputRef.current, 'id', "search-wrapper");
      wrapperInput && wrapperInput.classList.remove("is-dirty");
      setTimeout(() => this.searchInputRef.current.classList.remove("is-dirty"), 1000);
    };
  }

  render() {
    const searchList = this.state.arrayNames && this.state.arrayNames.map((item) => {
      return <li key={item} className={"search__list-item"} onClick={this.handleChoiceItem}>{item}</li>
    })

    return (
      <div className="mdl-textfield mdl-js-textfield mdl-textfield--expandable  search__input" data-id="search-wrapper" hidden={!this.props.isHidden}>
        {searchList && <ul className="search__list" hidden={this.state.isOpenSearchList}>{searchList}</ul>}
        <label className="mdl-button mdl-js-button mdl-button--icon" htmlFor="search">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-50 0 600 650">
            <g>
              <path fill="#3c66c4" d="M495,466.2L377.2,348.4c29.2-35.6,46.8-81.2,46.8-130.9C424,103.5,331.5,11,217.5,11C103.4,11,11,103.5,11,217.5   S103.4,424,217.5,424c49.7,0,95.2-17.5,130.8-46.7L466.1,495c8,8,20.9,8,28.9,0C503,487.1,503,474.1,495,466.2z M217.5,382.9   C126.2,382.9,52,308.7,52,217.5S126.2,52,217.5,52C308.7,52,383,126.3,383,217.5S308.7,382.9,217.5,382.9z" />
            </g>
          </svg>
        </label>
        <div className="mdl-textfield__expandable-holder">
          <input
            className="mdl-textfield__input"
            type="text"
            id="search"
            onChange={this.handleSearchInput}
            ref={this.searchInputRef}
            autoComplete={"off"}
          />
          <label className="mdl-textfield__label" htmlFor="sample-expandable"></label>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOneObject: (nameObj, value) => dispatch(getOneObject(nameObj, value)),
  }
};

export default connect(null, mapDispatchToProps)(Search);




